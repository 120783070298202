var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-toolbar-items",
    [
      !!_vm.id
        ? _c(
            "v-btn",
            {
              staticClass: "nav-button",
              attrs: { text: "", color: "#000" },
              on: { click: _vm.goBack },
            },
            [
              _c("v-icon", { attrs: { left: "", color: "#000" } }, [
                _vm._v("mdi-arrow-left"),
              ]),
              _vm._v(" Back "),
            ],
            1
          )
        : _vm._e(),
      _vm.me.is.superAdmin || _vm.me.is.transportationAdmin
        ? _c(
            "v-menu",
            {
              attrs: { "offset-y": "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "text-capitalize",
                                attrs: { color: "#000", text: "" },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _vm._v(" Operations "),
                            _c("v-icon", [_vm._v("mdi-menu-down")]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                3646849601
              ),
            },
            [
              _c(
                "v-list",
                [
                  _vm._l(_vm.operations, function (operation, index) {
                    return [
                      operation.show
                        ? _c(
                            "v-list-item",
                            { key: index, on: { click: operation.onClick } },
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(operation.name)),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }